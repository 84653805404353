import { Box, Spinner } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

const redLight = (

    <Spinner
    width="13px"
    height="13px"
    color='#f35858'
    speed='1.5s'
    />
)


const yellowLight = (
    <Box
    width="13px"
    height="13px"
    borderRadius="50%"
    bg='#fada23'

    />
)



const greenLight = (
    <CheckCircleIcon
    width="13px"
    height="13px"
    borderRadius="50%"
    color='#4cb742'

    />
)

export { redLight, yellowLight, greenLight };