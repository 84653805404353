function calculateSessionExpireTime() {
    // 현재 시간 (밀리초)
    const currentTime = Date.now();
    // 30분 (밀리초)
    const sessionDuration = 30 * 60 * 1000;
    // 세션 만료 시간 (Unix 정수 타임스탬프)
    const sessionExpireTime = (currentTime + sessionDuration);

    return sessionExpireTime;
}

function calculateAccessTokenExpireTime() {
    // 현재 시간 (밀리초)
    const currentTime = Date.now();
    // 30분 (밀리초)
    const accessTokenDuration = 30 * 60 * 1000;
    // 엑세스 토큰 만료 시간 (Unix 정수 타임스탬프)
    const accessTokenExpireTime = (currentTime + accessTokenDuration);
  
    return accessTokenExpireTime;
}

function calculateRefreshTokenExpireTime() {
    // 현재 시간 (밀리초)
    const currentTime = Date.now();
    // 1시간 (밀리초)
    const refreshTokenDuration = 60 * 60 * 1000;
    // 리프레시 토큰 만료 시간 (Unix 정수 타임스탬프)
    const refreshTokenExpireTime =(currentTime + refreshTokenDuration)

    return refreshTokenExpireTime;
}

function unixToReadableTime(unixTimestamp) {
    // Unix 타임스탬프를 밀리초로 변환
    const date = new Date(unixTimestamp);
    // 서울 시간대로 변환
    const options = { timeZone: 'Asia/Seoul', hour12: false };
    const readableTime = date.toLocaleString('ko-KR', options);
    
    return readableTime;
}

export {
    calculateSessionExpireTime,
    calculateAccessTokenExpireTime,
    calculateRefreshTokenExpireTime,
    unixToReadableTime
}
