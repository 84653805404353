import React, { createContext, useContext, useState } from 'react';
import { useColorMode } from '@chakra-ui/react';

const ColorContext = createContext(undefined);

export const useColorContext = () => {
    const context = useContext(ColorContext);
    if (context === undefined) {
        throw new Error('useColorContext must be used within a ColorProvider');
    }
    return context;
};

export const ColorProvider = ({ children }) => {
    const [colors, setColors] = useState({
        // ChatbotPage colors
        chatbotContainerPromptColor: { light: 'black', dark: 'white' },
        chatbotContainerMainBg: { light: 'white', dark: '#1E1E1E' },
        chatbotAssistantMessageBg: { light: '#F2DAC8', dark: '#444A67' },

        // RagPage colors
        ragContainerMainBg: { light: 'white', dark: 'black' },
        ragCardBg: { light: '#f5f5f5', dark: '#1E1E1E' },
        ragAssistantMessageBg: { light: '#f5f5f5', dark: '#1E1E1E' },
        ragCardIconBg: { light: '#e9e9e9', dark: '#2b2b2b' },
        ragFocusColor: { light: '#F2DAC8', dark: '#444A67' },
        ragModalBg: { light: 'white', dark: '#253C59' },

        // SignInPage colors
        signInContainerMainBg: { light: 'white', dark: 'black' },
        signInJoinBoxBg: { light: '#E2E2E2', dark: '#1E1E1E' },
        signInJoinBoxBorderColor: { light: '#A6A9B6', dark: 'gray' },

        // DocPage colors
        docNavigationBarBg: { light: '#e9e9e9', dark: '#2b2b2b' },
        docContainerPromptBg: { light: '#f5f5f5', dark: '#2B2B2B' },
        docContainerPromptColor: { light: 'black', dark: 'white' },
        docContainerMainBg: { light: 'white', dark: '#1E1E1E' },
        docContainerMainColor: { light: 'black', dark: 'white' },
        docUserMessageBg: { light: 'white', dark: 'black' },
        docUserMessageColor: { light: 'black', dark: 'white' },
        docAssistantMessageBg: { light: '#F2DAC8', dark: '#444A67' },
        docAssistantMessageColor: { light: 'black', dark: 'white' },
        docAssistantButtonClicked: { light: 'white', dark: 'black' },

        // SummaryPage colors
        summaryContainerCenterBg: { light: 'white', dark: '#1E1E1E' },
        summaryContainerMainBg: { light: '#f5f5f5', dark: '#2B2B2B' },
        summaryInputBoxBg: { light: 'gray.200', dark: 'black'},

        // WebSearchPage colors
        webSearchContainerPromptColor: { light: 'black', dark: 'white' },
        webSearchContainerMainBg: { light: 'white', dark: '#1E1E1E' },
    });

    const setColor = (page, colorName, lightColor, darkColor) => {
        setColors(prevColors => ({
            ...prevColors,
            [`${page}${colorName}`]: { light: lightColor, dark: darkColor }
        }));
    };

    const value = {
        colors,
        setColor
    };

    return <ColorContext.Provider value={value}>{children}</ColorContext.Provider>;
};

export const usePageColor = (pageName) => {
    const { colorMode } = useColorMode();
    const { colors } = useColorContext();
    
    const pageColors = {};
    for (const [key, value] of Object.entries(colors)) {
        if (key.startsWith(pageName)) {
            pageColors[key.replace(pageName, '')] = value[colorMode];
        }
    }
    
    return pageColors;
};