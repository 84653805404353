import { useState , useCallback, useRef, useEffect } from "react";
import { 
    Container, Box, Button, TabPanels,
    IconButton, HStack, Spacer, Text, Textarea, Input, 
    Flex, Center, Accordion, AccordionItem, AccordionButton,
    AccordionPanel, AccordionIcon, Tabs, TabList, Tab, Skeleton,
    Card, CardBody, Menu, MenuButton, MenuList, MenuItem, useToast, Stack,
    TabPanel, FormControl, FormLabel, Switch, 
    Divider} from "@chakra-ui/react";
import { ChevronDownIcon, RepeatIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { IoIosSend, IoMdDownload } from "react-icons/io";
import { FaRobot } from "react-icons/fa";
import { FaFloppyDisk } from "react-icons/fa6";
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import Markdown from "react-markdown";

import newTheme from '../components/mdstyle';
import { redLight, yellowLight, greenLight } from "../components/trafficLight";
import { 
    postdocCreateIndex, getDocGetContent, postDocDownload, 
    postAssistantMessage, putDocSave, getDocHistory, getDocMeta,
    deleteDocData, getTemplates
} from "../Api";
import LoadingOverlay from "../components/LoadingOverlayComponent";
import { scrollToBottom } from "../components/animation";
import { usePageColor } from "../contexts/ColorContext";

const DocPage = () => {

    const colors = usePageColor('doc')

    // 양식 탭에서 사용되는 상태
    const [allTemplates, setAllTemplates] = useState({result: []})
    const [documentPrompt, setDocumentPrompt] = useState('');
    const [documentGuidePrompt, setDocumentGuidePrompt] = useState('');
    const [documentTemplateId, setDocumentTemplateId] = useState(1);
    const [documentTemplateName, setDocumentTemplateName] = useState('업무보고서');
    const [documentTemplateOutline, setDocumnetTemplateOutline] = useState('1. 서론 \n 2. 목표 및 배경 \n 3. 진행 상황 \n 4. 문제점 및 해결 방안 \n 5. 결론 및 제안');


    // 아코디언
    const [accordionItemStates, setAccordionItemStates] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);

    // Ref들
    const documentPromptRef = useRef(null);
    const documentGuidePromptRef = useRef(null);
    const assistantInputRef = useRef(null);
    const messagesEndRef = useRef(null);
    const accordionButtonRefs = useRef([]);
    const [isUseNonSpecifiedFormat, setIsUseNonSpecifiedFormat] = useState(false);

    // 문서 목차 본문에 들어갈 내용
    const [documentIndexContent, setDocumentIndexContent] = useState({ result : [{        
        "doc_sub_id": 1,
        "doc_sub_name": "소제목",
        "contents": "내용",        
        "status": "init"
    }] });
    
    const [assistantMessageHistories, setAssistantMessageHistories] = useState({});
    const [isPutDocSaveButtonPop, setIsPutDocSaveButtonPop] = useState(false);
    const [triggerStatusCheck, setTriggerStatusCheck] = useState(false);

    const [docGenHistory, setDocGenHistory] = useState({result: [{
        
    }]})
    
    const [isDocumentIndexLoading, setIsDocumentIndexLoading] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);
    

    const toast = useToast();

    const handleGetDocTemplate = useCallback(async () => {

        try {
            const data = await getTemplates();
            if (data) {
                setAllTemplates(data);
                return;
            }
        } catch (error) {
            toast({
                title: 'Fetch Failed',
                description: '문서 목차 생성 실패. 관리자에게 문의하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }
        
    }, [toast])

    useEffect(()=>{
        handleGetDocTemplate()
    }, [handleGetDocTemplate])

    const toggleAccordionItemState = useCallback((itemId, stateType) => {

        const stateTypes = ["assistantSelected", "userEditSelected"];

        setAccordionItemStates(prevStates => {
            const newState = {
                ...prevStates,
                [itemId]: {
                    ...prevStates[itemId],
                    [stateType]: !prevStates[itemId]?.[stateType]
                }
            };
            // 다른 아이템의 상태를 모두 false로 설정
            Object.keys(newState).forEach(key => {
                if (key !== itemId.toString()) {
                    newState[key] = {
                        assistantSelected: false,
                        userEditSelected: false
                    };
                } else if (key === itemId.toString()) {
                    stateTypes.forEach(type => {
                        if (type !== stateType) {
                            newState[key][type] = false;
                        }
                    });
                }
            });
            return newState;
        });
        setSelectedItem(itemId);

    }, []);


    const handleStatusCheck = useCallback(async () => {
        if (!triggerStatusCheck || !documentIndexContent?.result?.length) {
            return false;
        }
    
        let allWritten = true;
    
        for (let index = 0; index < documentIndexContent.result.length; index++) {
            const item = documentIndexContent.result[index];
            const data = await getDocGetContent(item.doc_id, index + 1);

            if (data.result[0].length !== 0) {
                setDocumentIndexContent(prevContent => ({
                    ...prevContent,
                    result: prevContent.result.map((prevItem, i) => 
                        i === index ? {
                            ...prevItem,
                            status: data.result[0].status,
                            contents: data.result[0].contents
                        } : prevItem
                    )
                }));
    
                if (data.result[0].status === "Written") {
                    if (accordionButtonRefs.current[index].getAttribute('aria-expanded') === 'false') {
                        accordionButtonRefs.current[index].click()
    
                    }                            
                }
                
                if (data.result[0].status !== "Written") {
                    allWritten = false;
                    break;
                }
            }
            
        }
    
        return allWritten;
    }, [triggerStatusCheck, documentIndexContent]);
    
    useEffect(() => {
        let interval;
        if (documentIndexContent?.result?.length > 0 && triggerStatusCheck) {
            interval = setInterval(async () => {
                const allWritten = await handleStatusCheck();
                if (allWritten) {
                    clearInterval(interval);
                    toast({
                        title: 'Document Generation Completed!',
                        description: '문서 생성이 완료되었습니다.',
                        status: 'success',
                        isClosable: true,
                        position: 'top'
                    });
                    setTriggerStatusCheck(false);
                }
            }, 3000);
        }
    
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [documentIndexContent, handleStatusCheck, triggerStatusCheck, toast]);

    const handlePostdocCreateIndex = useCallback(async () => {
        
        try {
            if (documentPrompt === '') {
                toast({
                    title: 'Empty Prompt!',
                    description: '프롬프트를 입력하세요.',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
                documentPromptRef.current.focus();                
                return;
            }

            setIsFormLoading(true);
            setIsDocumentIndexLoading(true);
            
            const document = {
                "document_prompt": documentPrompt,
                "document_guide_prompt": documentGuidePrompt,
                "template_id": documentTemplateId,
                "template_name": documentTemplateName,
                "use_specified_format": !isUseNonSpecifiedFormat
                 
            }
            
            const data = await postdocCreateIndex(document);
             
            if (data['result']) {
                toast({
                    title: 'Getting Document Index Success!',
                    description: '문서 생성 시작 성공',
                    status: 'success',
                    isClosable: true,
                    position: 'top'
                })
                
                setDocumentIndexContent(data);
                setIsDocumentIndexLoading(false);
                setIsFormLoading(false);
                setTriggerStatusCheck(true);
                return;
            } else {
                toast({
                    title: 'Getting Document Index fails!',
                    description: '문서 목차 생성 실패. 관리자에게 문의하세요.',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                })
                setDocumentIndexContent({ result : [{
                    "doc_sub_id": 1,
                    "doc_sub_name": "소제목",
                    "contents": "내용",
                }] });
                return;
            }
            
        } catch (error) {
            toast({
                title: 'Fetch Failed',
                description: '문서 목차 생성 실패. 관리자에게 문의하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            setDocumentIndexContent({ result : [{
                "doc_sub_id": 1,
                "doc_sub_name": "소제목",
                "contents": "내용",
            }] });
            return;
        } finally{
            setIsFormLoading(false);
            setIsDocumentIndexLoading(false);
        }
    }, [documentGuidePrompt, documentPrompt, documentTemplateName, documentTemplateId, toast, isUseNonSpecifiedFormat]);

    
    const handleGetDocumentContent = useCallback(async (fileId, fileSubId) => {

        try {
            const data = await getDocGetContent(fileId, fileSubId);
            if (data['result']) {
                
                setDocumentIndexContent(prevContent => {
                    const updatedResult = prevContent.result.map(item => {
                        if (item.doc_sub_id === data.result[0].doc_sub_id) {
                            return {
                                ...item,
                                status: data.result[0].status,
                                contents: data.result[0].contents
                            };
                        }
                        return item;
                    });
                    
                    return {
                        ...prevContent,
                        result: updatedResult
                    };
                });
                return;
            } else {
                toast({
                    title: 'Getting Document Content fails!',
                    description: '문서 내용 가져오기 실패. 관리자에게 문의하세요.',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                })
                return;
            }
        } catch (error) {
            toast({
                title: 'Fetch Failed',
                description: '문서 내용 가져오기 실패. 관리자에게 문의하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }
        
    }, [toast]);

    const handlePostDocDownload = useCallback(async (fileId) => {
        try {
            const data = await postDocDownload(fileId);
            if (data.success === true) {
                toast({
                    title: 'Document Downloaded',
                    description: '다운로드가 완료되었습니다.',
                    status: 'success',
                    isClosable: true,
                    position: 'top'
                });
                
            } else {
                toast({
                    title: 'Fetch Failed',
                    description: '문서 가져오기 실패. 관리자에게 문의하세요.',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            }
        } catch (error) {
            console.error('Error in handlePostDocDownload:', error);
            toast({
                title: 'Fetch Failed',
                description: '문서 다운로드 실패했습니다. 관리자에게 문의하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
    }, [toast]);

    // 어시스턴트와 관련된 

    const handlePostAssistantMessage = useCallback(async (message, doc_sub_id, doc_sub_name, contents) => {
        if (message === '') {
            toast({
                title: 'Failed',
                description: '질문을 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }
    
        setAssistantMessageHistories(prevHistories => ({
            ...prevHistories,
            [doc_sub_id]: [
                ...(prevHistories[doc_sub_id] || []),
                { role: 'user', content: message },
                { role: 'assistant', content: '' }
            ]
        }));
    
        assistantInputRef.current.value = '';
    
        try {
            setIsPutDocSaveButtonPop(false);
            const stream = postAssistantMessage(message, doc_sub_id, doc_sub_name, contents);
            let fullResponse = '';
    
            const updateAssistantMessage = (content) => {
                setAssistantMessageHistories(prevHistories => {
                    const newHistory = [...(prevHistories[doc_sub_id] || [])];
                    newHistory[newHistory.length - 1].content = content;
                    return { ...prevHistories, [doc_sub_id]: newHistory };
                });
            };
    
            for await (let token of stream) {
                if (token.query_response) {
                    fullResponse += token.query_response;
                    updateAssistantMessage(fullResponse);
                    scrollToBottom(messagesEndRef);
                }
    
                if (token.last_answer === "Y") {
                    updateAssistantMessage(token.query_response);
                    setDocumentIndexContent(prevContent => ({
                        ...prevContent,
                        result: prevContent.result.map(item => 
                            item.doc_sub_id === doc_sub_id 
                                ? { ...item, contents: token.query_response }
                                : item
                        )
                    }));
                    break;
                }
            }
        } catch (error) {
            console.error('Error in handlePostAssistantMessage:', error);
        } finally {
            scrollToBottom(messagesEndRef);
            setIsPutDocSaveButtonPop(true);
        }
    }, [setAssistantMessageHistories, toast, assistantInputRef, setDocumentIndexContent, setIsPutDocSaveButtonPop]);


    const handlePutDocSave = useCallback(async (docId, subId, content) => {
        const thisDocumentDetailContent = {
            "doc_id": docId,
            "doc_sub_id": subId,
            "update_content": content
        }

        const selectedSection = documentIndexContent.result.find(item => item.doc_sub_id === subId);
        
        if (selectedSection.status !== "Written") {
            toast({
                title: 'Save Failed',
                description: '아직 하이퍼클로버가 해당 목차에 대한 문서를 생성하지 않았습니다.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }
        
        try {
            const data = await putDocSave(thisDocumentDetailContent);
            if (data["result"] === 1) {
                toast({
                    title: 'Document Saved',
                    description: '문서가 저장되었습니다.',
                    status: 'success',
                    isClosable: true,
                    position: 'top'
                });
                // 저장 성공 후 문서 내용 업데이트
                await handleGetDocumentContent(docId, subId);
            } else {
                toast({
                    title: 'Save Failed',
                    description: '문서 저장 실패. 관리자에게 문의하세요.',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            }
        } catch (error) {
            console.error('Error in handlePutDocSave:', error);
            toast({
                title: 'Fetch Failed',
                description: 'Failed to save the document content. Please contact the administrator.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
    }, [documentIndexContent, toast, handleGetDocumentContent]);

    const handleGetDocHistory = useCallback(async () => {
        try {
            const data = await getDocHistory();
            if (data['result']) {
                setDocGenHistory(data);                
                return;
            } else {
                toast({
                    title: 'Getting Document History fails!',
                    description: '문서 히스토리 가져오기 실패. 관리자에게 문의하세요.',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                })
                return;
            }
        } catch (error) {
            toast({
                title: 'Fetch Failed',
                description: '문서 히스토리 가져오기 실패. 관리자에게 문의하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }
    }, [toast, setDocGenHistory]);

    const handleRenderUserNameOnHistory = () =>{
        if (docGenHistory.result[0]) {
            return <Text>{docGenHistory.result[0]['user_name']} 님 안녕하세요!</Text>
        } else {
            return <Text>안녕하세요!</Text>
        }        
    }

    const handleGetDocMeta = useCallback(async (docId) => {
        try {
            const data = await getDocMeta(docId);
            if (data['result']) {
                setDocumentIndexContent(data);    
                await handleGetDocumentContent(docId, 1);
                // 어시스턴트 메시지 히스토리 초기화
                setAssistantMessageHistories({});
                return;        
            } else {
                toast({
                    title: 'Getting Document Content fails!',
                    description: '문서 내용 가져오기 실패. 관리자에게 문의하세요.',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                })
                return;
            }
        } catch (error) {
            toast({
                title: 'Fetch Failed',
                description: '문서 내용 가져오기 실패. 관리자에게 문의하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }
    }, [handleGetDocumentContent, toast]);

    const handleDeleteDocData = useCallback(async (docId) => {

        try {
            const data = await deleteDocData(docId);
            if (data["result"]) {
                toast({
                    title: 'Document Downloaded',
                    description: '삭제가 성공했습니다.',
                    status: 'success',
                    isClosable: true,
                    position: 'top'
                });
                setDocumentIndexContent({ result : [{        
                    "doc_sub_id": 1,
                    "doc_sub_name": "소제목",
                    "contents": "내용",        
                }] });
                return;
            }
        } catch (error) {
            toast({
                title: 'Fetch Failed',
                description: '삭제가 실패했습니다. 관리자에게 문의하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        } finally {
            handleGetDocHistory();
        }
    }, [handleGetDocHistory, toast]);
        

    const statusIcons = {
        "Written": greenLight,
        "Writting": yellowLight,
        "Not Written": redLight,
    };

    // console.log(documentDetailContent.result[0].doc_id);
    // console.log(documentDetailContent.result[0].doc_sub_name);
    // console.log("documentDetailContent!!!")
    // console.log(documentDetailContent);
    // console.log("documentIndexContent!!!")
    // console.log(documentIndexContent);
    // console.log(assistantMessageHistories);
    // console.log(docGenHistory);

    return (
        <Container bg={colors.ContainerMainBg} maxW='' size='container.3xl'>
            <HStack maxW='100%'>
                {!(selectedItem && (accordionItemStates[selectedItem]?.assistantSelected || accordionItemStates[selectedItem]?.userEditSelected)) && (
                    <Container
                    maxW = '35%'
                    h='95vh'
                    bg={colors.ContainerPromptBg}
                    color={colors.ContainerPromptColor}
                    position="relative"
                    >
                    <LoadingOverlay isLoading={isFormLoading} bgColor={colors.ContainerPromptBg} spinnerColor={colors.UserMessageColor}/>
                        <Flex direction='column' h='98%'>
                            <Box overflow='auto'>
                                <Tabs mt='1'>
                                <TabList>
                                    <Tab>양식</Tab>
                                    <Tab
                                    onClick={()=>{handleGetDocHistory()}}>히스토리</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                    <Flex direction='column' flex="100">
                                    <Container maxW='' mt='6'>                       
                                    <FormControl display='flex' alignItems='center' borderRadius='13' bg={colors.ContainerMainBg} p='5'>
                                        <FormLabel htmlFor='nontype-gen' mb='0'>
                                            활성화되면 HCX가 자동으로 목차를 생성합니다.
                                        </FormLabel>
                                        <Switch 
                                        id='nontype'
                                        onChange={
                                            ()=>{setIsUseNonSpecifiedFormat(prev=>!prev)}
                                        }/>
                                    </FormControl>
                                        
                                        <Card borderRadius='13px'
                                        mt='5'
                                        // opacity='0.8'
                                        >                                            
                                        <CardBody>
                                        <Flex>
                                            <Menu>
                                                <MenuButton 
                                                bg={colors.AssistantMessageBg} 
                                                as={Button} 
                                                rightIcon={<ChevronDownIcon />}
                                                borderRadius='13px'>
                                                    {documentTemplateName}
                                                </MenuButton>
                                                <MenuList  borderRadius='13px'>
                                                    {allTemplates.result.map((template, index) => (
                                                        <MenuItem
                                                        key={index}
                                                        onClick={()=>{                                                    
                                                            setDocumentTemplateId(template.template_id);
                                                            setDocumentTemplateName(template.template_name);
                                                            setDocumnetTemplateOutline(template.template_outline);
                                                        }}>
                                                            {template.template_name}
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                            </Menu>
                                            <Spacer />
                                            <HStack>
                                            <IconButton
                                            icon={<RepeatIcon />}
                                            borderRadius='13px'
                                            onClick={()=>{
                                                documentPromptRef.current.value = '';
                                                documentGuidePromptRef.current.value = '';
                                                setDocumentPrompt('');
                                                setDocumentGuidePrompt('');
                                                setDocumentTemplateId(1);
                                            }}
                                            />
                                            <IconButton 
                                            icon={<IoIosSend />}
                                            colorScheme="blue"
                                            borderRadius='13px'
                                            onClick={()=>{
                                                handlePostdocCreateIndex();                     
                                            }}
                                            />
                                            </HStack>                                    
                                        </Flex>
                                            <Flex direction='column' mt='7'>
                                                <Text ml='3'>프롬프트 입력</Text>
                                                <Textarea
                                                    mt='2'
                                                    placeholder="프롬프트를 입력하세요"
                                                    borderRadius='13px'
                                                    borderWidth='2px'
                                                    ref = {documentPromptRef}
                                                    onChange={(e) => {
                                                        setDocumentPrompt(e.target.value);
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handlePostdocCreateIndex();
                                                        }
                                                    }}
                                                />
                                            </Flex>
                                            
                                            <Flex direction='column' mt='5'>
                                                <Text ml='3'>문서 작성 가이드</Text>
                                                <Textarea
                                                    mt='2'
                                                    placeholder="추가로 문서 작성 가이드를 내리세요"
                                                    borderRadius='13px'
                                                    borderWidth='2px'
                                                    ref = {documentGuidePromptRef}
                                                    onChange={(e) => {
                                                        setDocumentGuidePrompt(e.target.value);
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handlePostdocCreateIndex();
                                                        }
                                                    }}
                                                />
                                            </Flex>
                                            {!isUseNonSpecifiedFormat && (
                                            <Flex direction='column' mt='5'>
                                                <Text ml='3'>문서 목차 형식</Text>                                                
                                                <Text whiteSpace="pre-line" mt='2' borderRadius='13px' borderWidth='2px' p='3'>
                                                    {documentTemplateOutline.replace(/\\n/g, '\n')}
                                                </Text>
                                            </Flex>
                                            )}
                                        </CardBody>
                                        </Card>
                                    </Container>
                                    <Spacer/>
                                    </Flex>
                                    </TabPanel>
                                    <TabPanel overflow='auto' h='98%'>
                                        {handleRenderUserNameOnHistory()}
                                        {(() => {
                                            const todayDocs = docGenHistory.result.filter(history => {
                                                const docTime = new Date(history.doc_gen_time).getTime();
                                                const now = Date.now();
                                                return docTime < now && docTime > now - 86400000; // 오늘
                                            });
                                            return todayDocs.length > 0 && (
                                                <>
                                                    <Text mt='7'>오늘</Text>
                                                    {todayDocs.map(history => (
                                                        <Flex key={history.doc_id} mt='3'>
                                                            <Button
                                                                borderRadius='13px'
                                                                onClick={() => handleGetDocMeta(history.doc_id)}
                                                                bg='transparent'
                                                                borderWidth='2px'
                                                                whiteSpace="nowrap"
                                                                overflow="hidden"
                                                                textOverflow="ellipsis"                                                                
                                                                display="block"
                                                                maxWidth="70%"
                                                            >
                                                                {history.doc_name}
                                                            </Button>
                                                            <Spacer />
                                                            <IconButton 
                                                                icon={<DeleteIcon />}
                                                                bg='transparent'
                                                                onClick={() => handleDeleteDocData(history.doc_id)}
                                                            />                                     
                                                        </Flex>
                                                    ))}
                                                    <Divider mt='3' />
                                                </>
                                            );
                                        })()}
                                        {(() => {
                                            const yesterdayDocs = docGenHistory.result.filter(history => {
                                                const docTime = new Date(history.doc_gen_time).getTime();
                                                const now = Date.now();
                                                return docTime < now - 86400000 && docTime > now - 2 * 86400000; // 어제
                                            });
                                            return yesterdayDocs.length > 0 && (
                                                <>
                                                    <Text mt='7'>어제</Text>
                                                    {yesterdayDocs.map(history => (
                                                        <Flex key={history.doc_id} mt='3'>
                                                            <Button
                                                                borderRadius='13px'
                                                                onClick={() => handleGetDocMeta(history.doc_id)}
                                                                bg='transparent'
                                                                borderWidth='2px'
                                                                whiteSpace="nowrap"
                                                                overflow="hidden"
                                                                textOverflow="ellipsis"                                                            
                                                                display="block"
                                                                maxWidth="70%"
                                                            >
                                                                {history.doc_name}
                                                            </Button>
                                                            <Spacer />
                                                            <IconButton 
                                                                icon={<DeleteIcon />}
                                                                bg='transparent'
                                                                onClick={() => handleDeleteDocData(history.doc_id)}
                                                            />                                     
                                                        </Flex>
                                                    ))}
                                                    <Divider mt='3' />
                                                </>
                                            );
                                        })()}
                                        {(() => {
                                            const last7DaysDocs = docGenHistory.result.filter(history => {
                                                const docTime = new Date(history.doc_gen_time).getTime();
                                                const now = Date.now();
                                                return docTime < now - 86400000 && docTime > now - 7 * 86400000; // 지난 7일
                                            });
                                            return last7DaysDocs.length > 0 && (
                                                <>
                                                    <Text mt='7'>지난 7일</Text>
                                                    {last7DaysDocs.map(history => (
                                                        <Flex key={history.doc_id} mt='3'>
                                                            <Button
                                                                borderRadius='13px'
                                                                onClick={() => handleGetDocMeta(history.doc_id)}
                                                                bg='transparent'
                                                                borderWidth='2px'
                                                                whiteSpace="nowrap"
                                                                overflow="hidden"
                                                                textOverflow="ellipsis"
                                                                display="block"
                                                                maxWidth="70%"
                                                            >
                                                                {history.doc_name}
                                                            </Button>
                                                            <Spacer />
                                                            <IconButton 
                                                                icon={<DeleteIcon />}
                                                                bg='transparent'
                                                                onClick={() => handleDeleteDocData(history.doc_id)}
                                                            />                                     
                                                        </Flex>
                                                    ))}
                                                    <Divider mt='3' />
                                                </>
                                            );
                                        })()}
                                    </TabPanel>
                                </TabPanels>
                                </Tabs>
                            </Box>                        
                        </Flex>
                    </Container>
                )}              
                <Container
                    maxW = '65%'
                    h='95vh'
                    bg={colors.ContainerMainBg}
                    color={colors.ContainerMainColor}
                >
                    <Flex direction='column' h='100%'>
                        <Box borderWidth='2px' borderRadius='16px' h='95%' mt='1%' overflowY='scroll' p='3'>
                            <Container maxW='container.xl' alignContent='center'>
                                <Box>
                                    <Card mt='5' borderRadius='13px' bg={colors.ContainerPromptBg}>
                                        <CardBody>
                                            <Flex>
                                                <Center 
                                                fontSize='2xl' 
                                                fontWeight='bold'
                                                maxW='70%'
                                                overflow='hidden'
                                                whiteSpace='nowrap'
                                                textOverflow='ellipsis'
                                                display="block"                                                                          
                                                >
                                                    {isFormLoading && (
                                                        <Skeleton height='40px' />
                                                    )}
                                                    {!isFormLoading && (
                                                        documentIndexContent.result[0].doc_name? 
                                                        documentIndexContent.result[0].doc_name : "목차"
                                                    )}                                                    
                                                </Center>
                                                <Spacer />
                                                <HStack>
                                                    <IconButton 
                                                        icon={<IoMdDownload />}
                                                        borderRadius='13px'
                                                        bg={colors.NavigationBarBg}
                                                        onClick={() => handlePostDocDownload(documentIndexContent.result[0].doc_id)}
                                                    />
                                                </HStack>
                                            </Flex>                                
                                        </CardBody>
                                    </Card>
                                    {isDocumentIndexLoading && (
                                        <Stack pt='30px'>
                                            <Skeleton height='40px' />
                                            <Skeleton height='40px' />
                                            <Skeleton height='40px' />
                                            <Skeleton height='40px' />
                                            <Skeleton height='40px' />
                                            <Skeleton height='40px' />
                                        </Stack>
                                    )}
                                    {!isDocumentIndexLoading && (
                                      <Accordion allowMultiple mt='10'>
                                      {documentIndexContent.result.map((section, index) => (
                                          <AccordionItem key={section.doc_sub_id}>
                                              <AccordionButton
                                                  ref={el => accordionButtonRefs.current[index] = el}
                                                  onClick={() => {                                                        
                                                      handleGetDocumentContent(section.doc_id, section.doc_sub_id);
                                                  }}
                                              >
                                                  <HStack w="100%">
                                                      <Box flex="1" textAlign="left">
                                                          {section.doc_sub_name ? section.doc_sub_name : ""}
                                                      </Box>
                                                      {statusIcons[section.status]}
                                                      <Spacer />
                                                      <IconButton
                                                          icon={<FaRobot />}
                                                          size="sm"
                                                          onClick={(e) => {
                                                              e.stopPropagation();
                                                              toggleAccordionItemState(section.doc_sub_id, 'assistantSelected');
                                                          }}
                                                          bg={accordionItemStates[section.doc_sub_id]?.assistantSelected ? colors.AssistantButtonClicked : colors.NavigationBarBg}
                                                      />
                                                      <IconButton
                                                          icon={<EditIcon />}
                                                          size="sm"
                                                          onClick={(e) => {
                                                              e.stopPropagation();
                                                              toggleAccordionItemState(section.doc_sub_id, 'userEditSelected');
                                                          }}
                                                          bg={accordionItemStates[section.doc_sub_id]?.userEditSelected ? colors.AssistantButtonClicked : colors.NavigationBarBg}
                                                      />
                                                      {/* <IconButton
                                                          icon={<FaFloppyDisk />}
                                                          size="sm"
                                                          onClick={(e) => {
                                                              e.stopPropagation();
                                                              handlePutDocSave(section.doc_id, section.doc_sub_id, section.contents);
                                                          }}
                                                          bg={colors.NavigationBarBg}
                                                      /> */}
                                                      <AccordionIcon />
                                                  </HStack>
                                              </AccordionButton>
                                              <AccordionPanel pb={4}>                                                
                                                  <Markdown components={ChakraUIRenderer(newTheme)} skipHtml>
                                                      {section.contents ? section.contents : ""}
                                                  </Markdown>
                                              </AccordionPanel>
                                          </AccordionItem>
                                      ))}
                                  </Accordion>  
                                    )}
                                </Box>
                            </Container>
                        </Box>
                    </Flex>
                </Container>
                
                {selectedItem && (accordionItemStates[selectedItem]?.assistantSelected || accordionItemStates[selectedItem]?.userEditSelected) && (
                <Container maxW='35%' h='95vh' bg={colors.ContainerPromptBg} color={colors.ContainerPromptColor}>
                    <Flex direction='column' h='98%'>
                        <Box>
                            <Tabs mt='1'>
                                <TabList>
                                    <Tab>
                                        {accordionItemStates[selectedItem]?.assistantSelected ? '채팅' : '편집'}
                                    </Tab>
                                </TabList>
                            </Tabs>
                        </Box>
                        {accordionItemStates[selectedItem]?.assistantSelected ? (
                            <>
                                <Spacer />
                                <Flex direction="column" flex="100" overflowY='auto' mt='5'>
                                    <Text>{documentIndexContent.result[selectedItem-1].doc_sub_name}에 대해 수정하고 싶은 사항을 보내보세요!</Text>
                                    <Box ref={messagesEndRef}>
                                        {(assistantMessageHistories[selectedItem] || []).map((message, index) => (
                                            <Flex
                                                key={index}
                                                justify={message.role === 'user' ? 'flex-end' : 'flex-start'}
                                                mb="3"                                                
                                            >   
                                                <Flex direction='column'>
                                                <Box
                                                    bg={message.role === 'user' ? colors.UserMessageBg : colors.AssistantMessageBg}
                                                    color={message.role === 'user' ? colors.UserMessageColor : colors.AssistantMessageColor}
                                                    p="3"
                                                    borderRadius="12px"
                                                    maxW="70%"
                                                    alignSelf={message.role === 'user' ? 'flex-end' : 'flex-start'}
                                                >
                                                    {message.role === 'assistant' ? (
                                                        <>                                                                                                            
                                                        <Markdown components={ChakraUIRenderer(newTheme)} skipHtml>
                                                            {message.content}
                                                        </Markdown>                                                        
                                                        </>

                                                    ) : (
                                                            <Text>{message.content}</Text>
                                                    )}
                                                </Box>
                                                {isPutDocSaveButtonPop && message.role ==='assistant'? (                                        
                                                    <IconButton
                                                    icon={<FaFloppyDisk />}
                                                    size="md"
                                                    maxW="70%"
                                                    mt='3'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handlePutDocSave(
                                                            documentIndexContent.result.find(item => item.doc_sub_id === selectedItem).doc_id, 
                                                            selectedItem,
                                                            message.content
                                                        );
                                                    }} 
                                                    />                                                                                                                                                                               
                                                ):(
                                                    <></>
                                                )}
                                                </Flex>
                                                
                                            </Flex>
                                        ))}
                                    </Box>
                                </Flex>
                                <Spacer />
                                <Card borderRadius='13px'>
                                    <CardBody>
                                        <HStack>
                                            <Input
                                                placeholder='Text here...'
                                                borderWidth='2px'
                                                borderRadius='13px'
                                                w='100%'
                                                ref={assistantInputRef}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        const selectedSection = documentIndexContent.result.find(item => item.doc_sub_id === selectedItem);
                                                        handlePostAssistantMessage(
                                                            assistantInputRef.current.value,
                                                            selectedSection.doc_sub_id,
                                                            selectedSection.doc_sub_name,
                                                            selectedSection.contents
                                                        );
                                                    }
                                                }}
                                            />
                                            <IconButton 
                                                icon={<IoIosSend />}
                                                borderRadius='13px'
                                                onClick={() => {
                                                    const selectedSection = documentIndexContent.result.find(item => item.doc_sub_id === selectedItem);
                                                    handlePostAssistantMessage(
                                                        assistantInputRef.current.value,
                                                        selectedSection.doc_sub_id,
                                                        selectedSection.doc_sub_name,
                                                        selectedSection.contents
                                                    );
                                                }}                                
                                            />
                                        </HStack>
                                    </CardBody>
                                </Card>
                            </>
                            ) : (
                                // 편집 UI 컴포넌트
                                <Card borderRadius='13px' mt='5'>
                                    <CardBody>
                                        <HStack>
                                            <Text>
                                                {documentIndexContent.result.find(item => item.doc_sub_id === selectedItem)?.doc_sub_name || ""}
                                            </Text>
                                            <Spacer />
                                            <IconButton 
                                                icon={<FaFloppyDisk />}
                                                onClick={(e)=>{
                                                    e.stopPropagation();
                                                    handlePutDocSave(
                                                        documentIndexContent.result.find(item => item.doc_sub_id === selectedItem).doc_id, 
                                                        selectedItem,
                                                        documentIndexContent.result.find(item => item.doc_sub_id === selectedItem)?.contents || ""
                                                    );
                                                }}
                                            />                                            
                                        </HStack>
                                        <Textarea 
                                            mt='5' 
                                            borderRadius='13px' 
                                            minH='400' 
                                            onChange={(e) => {
                                                setDocumentIndexContent(prevContent => {
                                                    const updatedResult = prevContent.result.map(item => {
                                                        if (item.doc_sub_id === selectedItem) {
                                                            return {
                                                                ...item,
                                                                contents: e.target.value
                                                            };
                                                        }
                                                        return item;
                                                    });
                                                    return {
                                                        ...prevContent,
                                                        result: updatedResult
                                                    };
                                                });
                                            }}
                                            value={documentIndexContent.result.find(item => item.doc_sub_id === selectedItem)?.contents || ""}
                                        />
                                    </CardBody>
                                </Card>
                            )}
                        </Flex>
                    </Container>
                )}
            </HStack>
        </Container>
    );
}

export default DocPage;
