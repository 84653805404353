import React, { useState, useEffect, useRef } from 'react';
import { 
    Modal, ModalOverlay, ModalBody, ModalFooter, 
    ModalContent, ModalHeader, ModalCloseButton, Box,
    Button, Text, VStack, useToast, Code, IconButton, HStack, Switch
} from '@chakra-ui/react';

import { RepeatIcon } from '@chakra-ui/icons'
import { IoIosSend } from 'react-icons/io';
import { FaRecordVinyl } from "react-icons/fa";

import {postSTT} from '../Api'
import LoadingOverlay from './LoadingOverlayComponent'
import { usePageColor } from '../contexts/ColorContext';

const HCXVoiceComponent = ({ 
    isVoiceModalOpen, 
    onVoiceModalClose, 
}) => {
    const colors = usePageColor('chatbot');
    
    const [isRecording, setIsRecording] = useState(false);
    const [audioContext, setAudioContext] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioBlob, setAudioBlob] = useState(null);
    const [recordedAudioUrl, setRecordedAudioUrl] = useState(null);
    const [apiResponseAudioUrl, setApiResponseAudioUrl] = useState(null);
    const [resultText, setResultText] = useState('');
    const [recordButtonColorSchemeWhileRecording, setRecordButtonColorSchemeWhileRecording] = useState('yellow')
    const [autoPlay, setAutoPlay] = useState(true);
    const [isApiLoading, setIsApiLoading] = useState(false);
    
    const recordedAudioPlayerRef = useRef(null);
    const apiResponseAudioPlayerRef = useRef(null);
    const audioChunksRef = useRef([]);
    const toast = useToast();

    useEffect(() => {
        if (apiResponseAudioUrl && autoPlay) {
            if (apiResponseAudioPlayerRef.current) {
                apiResponseAudioPlayerRef.current.src = apiResponseAudioUrl;
                apiResponseAudioPlayerRef.current.play().catch(error => {
                    console.error("Auto-play failed:", error);
                    toast({
                        title: "Auto-play failed",
                        description: "Browser settings might be blocking auto-play. Please use the play button.",
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                    });
                });
            }
        }
    }, [apiResponseAudioUrl, autoPlay, toast]);

    const isExistAudioDevice = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioDevices = devices.filter(device => device.kind === 'audioinput');
            return audioDevices.length > 0;
        } catch (error) {
            return false;
        }
    };

    const handleResetRecording = () => {
        if (mediaStream) {
            mediaStream.getAudioTracks().forEach(track => track.stop());
            setMediaStream(null);
        }
        if (mediaRecorder) {
            mediaRecorder.stop();
            setMediaRecorder(null);
        }
        if (audioContext && audioContext.state !== 'closed') {
            audioContext.close().catch(error => console.error("AudioContext close error: ", error));
            setAudioContext(null);
        }
        audioChunksRef.current = [];
        setAudioBlob(null);
        setRecordedAudioUrl(null);
        setApiResponseAudioUrl(null);
        setResultText('');
        if (recordedAudioPlayerRef.current) {
            recordedAudioPlayerRef.current.pause();
            recordedAudioPlayerRef.current.src = '';
        }
        if (apiResponseAudioPlayerRef.current) {
            apiResponseAudioPlayerRef.current.pause();
            apiResponseAudioPlayerRef.current.src = '';
        }
        setIsRecording(false);
    };

    useEffect(() => {
        let intervalId;
        let lightningCounter = 1;
        if (isRecording) {
            intervalId = setInterval(() => {
                lightningCounter++;
                setRecordButtonColorSchemeWhileRecording(lightningCounter % 2 === 0 ? 'orange' : 'yellow');
            }, 1000);
        }
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [isRecording]);

    const handleStartRecording = async () => {
        try {
            if (!(await isExistAudioDevice())) {
                throw new Error('마이크가 존재하지 않습니다.');
            }
            setIsRecording(true);

            if (recordedAudioPlayerRef.current) {
                recordedAudioPlayerRef.current.pause();
                recordedAudioPlayerRef.current.src = '';
            }
            const _audioContext = new AudioContext();
            setAudioContext(_audioContext);
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
            setMediaStream(stream);

            let mimeType = 'audio/webm;codecs=opus';
            if (!MediaRecorder.isTypeSupported(mimeType)) {
                mimeType = 'audio/ogg;codecs=opus';
            }
            const options = { mimeType };
            const _mediaRecorder = new MediaRecorder(stream, options);
            setMediaRecorder(_mediaRecorder);

            _mediaRecorder.ondataavailable = e => {
                console.log('Data available:', e.data.size);
                audioChunksRef.current.push(e.data);
            };
            _mediaRecorder.onstop = () => {
                console.log('Recording stopped, chunks:', audioChunksRef.current.length);
                const _audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
                console.log('Blob created:', _audioBlob.size);
                setAudioBlob(_audioBlob);
                const url = URL.createObjectURL(_audioBlob);
                setRecordedAudioUrl(url);
                audioChunksRef.current = [];
            };

            _mediaRecorder.start();
        } catch (error) {
            handleResetRecording();
            toast({
                title: "Error",
                description: `녹음 중 오류가 발생했습니다: ${error.message}`,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleStopRecording = () => {
        console.log("handleStopRecording 눌림")
        setIsRecording(false);
        
        if (mediaRecorder && mediaRecorder.state !== 'inactive') {
            mediaRecorder.stop();
        }
        if (mediaStream) {
            mediaStream.getAudioTracks().forEach(track => track.stop());
        }
        if (audioContext && audioContext.state !== 'closed') {
            audioContext.close().catch(error => console.error("AudioContext close error: ", error));
        }
    };

    const handlePostSTT = async () => {
        setIsApiLoading(true);
        try {
            if (!audioBlob) {
                throw new Error('녹음된 오디오가 없습니다.');
            }

            const requestData = {
                "blog_yn": false,
                "news_yn": false,
                "search_type": "docs",
                "raptor_type": "collapsed"
            }

            const response = await postSTT(audioBlob, requestData);
            
            setResultText(response.text_data);
            
            // Base64 디코딩 및 Blob 생성
            const byteCharacters = atob(response.audio_data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: 'audio/mp3'});
            const url = URL.createObjectURL(blob);
            setApiResponseAudioUrl(url);
            setIsApiLoading(false);
        } catch (error) {
            setIsApiLoading(false);
            toast({
                title: "Error",
                description: `API 호출 중 오류가 발생했습니다: ${error.message}`,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Modal isOpen={isVoiceModalOpen} onClose={onVoiceModalClose}>
            <ModalOverlay />
            <ModalContent borderRadius='16px' position='relative'>
            <LoadingOverlay isLoading={isApiLoading} bgColor={colors.ContainerMainBg} />
                <ModalHeader>AMP-Service Voice</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack align="stretch" spacing={4}>
                        <HStack>
                            {recordedAudioUrl && !isRecording && (
                                <>
                                    <audio 
                                        controls 
                                        ref={recordedAudioPlayerRef}
                                        src={recordedAudioUrl}
                                    />
                                    <IconButton 
                                        colorScheme='blue' 
                                        onClick={handlePostSTT} 
                                        icon={<IoIosSend/>}
                                        borderRadius='13'
                                    />    
                                    <IconButton 
                                        onClick={handleResetRecording} 
                                        icon={<RepeatIcon />}
                                        borderRadius='13'
                                    />   
                                </>                        
                            )}                        
                        </HStack>
                        <Box display="none">
                            <audio 
                                ref={apiResponseAudioPlayerRef}
                                src={apiResponseAudioUrl}
                            />
                        </Box>
                        <HStack>
                            <Text>Auto-play response:</Text>
                            <Switch 
                                isChecked={autoPlay} 
                                onChange={(e) => setAutoPlay(e.target.checked)}
                            />
                        </HStack>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        onClick={isRecording ? handleStopRecording : handleStartRecording} 
                        mr='2'
                        colorScheme={isRecording ? recordButtonColorSchemeWhileRecording : "green"}
                        borderRadius='13'
                        rightIcon={<FaRecordVinyl />}
                    >
                        {isRecording ? "녹음 완료" : "녹음 시작"}
                    </Button>
                </ModalFooter>
                {audioBlob && !isRecording && (
                    <VStack align="stretch" spacing={4} p={4}>
                        <Code>{`Type: ${audioBlob.type}`}</Code>
                        <Code>{`Size: ${audioBlob.size} bytes`}</Code>
                        <Code>{`API Result Text: ${resultText || 'N/A'}`}</Code>
                    </VStack>
                )}
            </ModalContent>
        </Modal>
    );
}

export default HCXVoiceComponent;