import { Button, HStack, IconButton, useColorModeValue } from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";

const Pagination = ({ selectedPage, setSelectedPage, allRowsNumber, rowsNumberPerPage }) => {
  const allNumberBoxes = Math.ceil(allRowsNumber / rowsNumberPerPage);
  const boxesBg = useColorModeValue("gray.200", "gray.700");
  const activeColor = useColorModeValue("black", "white");
  const inactiveColor = useColorModeValue("gray", "gray");

  return (
    <>
      {allNumberBoxes > 1 && (
        <HStack>
          <IconButton
          icon={<ArrowLeftIcon />}
          onClick={() => setSelectedPage(1)}
          borderRadius='13px'
          bg='transparent'
          />
          {Array.from({ length: allNumberBoxes }, (_, i) => (
            <Button
              key={i}
              bg={boxesBg}
              fontSize="20px"
              onClick={() => setSelectedPage(i + 1)}
              color={selectedPage === i + 1 ? activeColor : inactiveColor}
              borderRadius='13px'
            >
              {i + 1}
            </Button>
          ))}
          <IconButton
          icon={<ArrowRightIcon />}
          onClick={() => setSelectedPage(allNumberBoxes)}
          borderRadius='13px'
          bg='transparent'
          />
        </HStack>
      )}
    </>
  );
}

export default Pagination;