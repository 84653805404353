import { 
    Container, Box, IconButton, HStack, Spacer, Text, useColorMode, useColorModeValue,   
    Menu, MenuButton, MenuList, MenuItem, useDisclosure, Button,
    MenuDivider, useBreakpointValue,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon, HamburgerIcon, SettingsIcon, CloseIcon } from '@chakra-ui/icons';
import { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom"; // Import NavLink and useNavigate from react-router-dom
import { MdSpaceDashboard } from "react-icons/md";
import { IoDocument } from "react-icons/io5";
import { FaRobot, FaPlayCircle, FaSignOutAlt, FaSignInAlt } from "react-icons/fa";
import { useGlobal } from "../contexts/GlobalContext";

const NavigationBar = ({checkExpireTime}) => {
    const { toggleColorMode } = useColorMode();
    const [nightMode, setNightMode] = useState(true);
    const navigate = useNavigate();
    const [tokenAndSessionInfo, setTokenAndSessionInfo] = useState({});
    
    const { isSignedIn, setIsSignedIn } = useGlobal();

    const { isOpen: isControlCenterOpen, onOpen: onControlCenterOpen, onClose: onControlCenterClose } = useDisclosure();

    const handleToggleNightMode = () => {
        setNightMode(!nightMode);
        toggleColorMode();
    };

    const navigationBarBg = useColorModeValue('#e9e9e9', '#333333');
    const navigationBarColor = useColorModeValue('black', 'white');
    const controlCenterBg = useColorModeValue('#333333', '#e9e9e9');
    const controlCenterColor = useColorModeValue('white', 'black');
    const selectedPageColor = useColorModeValue('blue.400', 'orange');

    const [currentPage, setCurrentPage] = useState(window.location.pathname);

    useEffect(() => {
        if (!isSignedIn && (currentPage === '/rag') ) {
            navigate('/signin');
        } else if (isSignedIn && currentPage === '/signin') {
            navigate('/rag');
        }
    }, [isSignedIn, currentPage, navigate]);

    const handleSignOut = () => {
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('session-exp');
        localStorage.removeItem('access-token-exp');
        localStorage.removeItem('refresh-token-exp');
        setIsSignedIn(false);
        navigate('/signin');
    };

    const check = async () => {
        const result = await checkExpireTime();
        if (result === false) {
            navigate('/signin')
        }
        setTokenAndSessionInfo(result);
    };

    useEffect(() => {
        const handleLocationChange = () => {
            setCurrentPage(window.location.pathname);
        };
    
        window.addEventListener('popstate', handleLocationChange);
    
        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, []);

    const disappearComponent = useBreakpointValue({ base: true, lg: false });

    return (
        <Container
            maxW='100%'
            size="container.3xl"
            h='6vh'
            bg={navigationBarBg}
            color={navigationBarColor}
        >
            <HStack alignItems="center" h="100%">
                <HStack spacing={40}>
                <Text fontWeight='bold' as={Link} to="/">
                    Didim AMP Service
                </Text>
                {!disappearComponent && (
                    <>
                    {!isSignedIn && (
                    <HStack spacing={10}>
                        <Button as={Link} to="/" leftIcon={<FaRobot />} bg='transparent'>
                            Chat
                        </Button>
                        <Button as={Link} to="/doc" leftIcon={<MdSpaceDashboard />} bg='transparent'>
                            Generate
                        </Button>
                        <Button as={Link} to="/playground" leftIcon={<FaPlayCircle  />} bg='transparent'>
                            Playground
                        </Button>            
                    </HStack>              
                    )}
                    
                    {isSignedIn && (
                    <HStack spacing={10}>
                        <Button as={Link} to="/" leftIcon={<FaRobot />} bg='transparent'>
                            Chat
                        </Button>                    
                        <Button as={Link} to="/doc" leftIcon={<MdSpaceDashboard />} bg='transparent'>
                            Generate
                        </Button>
                        <Button as={Link} to="/playground" leftIcon={<FaPlayCircle  />} bg='transparent'>
                            Playground
                        </Button> 
                        <Button as={Link} to="/rag" leftIcon={<IoDocument />} bg='transparent'>
                            RAG
                        </Button>                    
                    </HStack>              
                    )}
                    </>
                )}                                
                </HStack>                
                <Spacer />
                <Box>
                    <IconButton 
                        icon={isSignedIn? <FaSignOutAlt/>:<FaSignInAlt/> }
                        bg='transparent'
                        onClick={isSignedIn? ()=>{handleSignOut()} : ()=>{navigate('/signin')}}
                    />
                    <IconButton
                        icon={nightMode ?  <MoonIcon /> : <SunIcon />}
                        onClick={handleToggleNightMode}
                        bg='transparent'
                    />
                    {isSignedIn && (
                    <IconButton 
                        icon={<SettingsIcon />} 
                        onClick={()=>{
                            check()
                            if (!isControlCenterOpen) {
                                onControlCenterOpen();
                            } else {
                                onControlCenterClose();
                            }
                        }} 
                        aria-label="Settings"
                        bg='transparent'
                    />
                    )}                    
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            icon={<HamburgerIcon />}
                            variant='outline'
                            borderWidth='0px'
                            bg='transparent'
                        />
                        <MenuList>
                            {!isSignedIn && (
                                <>            
                                <MenuItem 
                                    icon={<FaRobot />} 
                                    as={NavLink} // Use NavLink from react-router-dom
                                    to="/"
                                    command="/"
                                    onClick={() => {
                                        setCurrentPage('/');
                                    }}
                                    color={currentPage === '/' ? selectedPageColor : ''}
                                >
                                    Chat with HCX
                                </MenuItem>       
                                <MenuItem
                                    icon={<MdSpaceDashboard />} 
                                    as={NavLink} // Use NavLink from react-router-dom
                                    to="/doc"
                                    command="/doc"
                                    onClick={() => {
                                        setCurrentPage('/doc');
                                    }}
                                    color={currentPage === '/doc' ? selectedPageColor : ''}
                                >
                                    Generate Documents                        
                                </MenuItem>
                                <MenuItem
                                    icon={<FaPlayCircle />}
                                    as={NavLink}
                                    to="/playground"
                                    command="/playground"
                                    onClick={() => {
                                        setCurrentPage('/playground');
                                    }}
                                    color={currentPage === '/playground' ? selectedPageColor : ''}
                                >
                                    Playground
                                </MenuItem>
                                <MenuItem 
                                    icon={<FaSignInAlt />} 
                                    as={NavLink} // Use NavLink from react-router-dom
                                    to="/signin"
                                    command="/signin"
                                    onClick={() => {
                                        setCurrentPage('/signin');
                                    }}
                                    color={currentPage === '/signin' ? selectedPageColor : ''}
                                >
                                    Sign in
                                </MenuItem>
                                </>                                
                            )}                   
                            {isSignedIn && (
                                <>
                                    <MenuItem 
                                        icon={<FaRobot />} 
                                        as={NavLink} // Use NavLink from react-router-dom
                                        to="/"
                                        command="/"
                                        onClick={() => {
                                            setCurrentPage('/');
                                        }}
                                        color={currentPage === '/' ? selectedPageColor : ''}
                                    >
                                        Chat with HCX
                                    </MenuItem>                     
                                    <MenuItem
                                        icon={<MdSpaceDashboard />}
                                        as={NavLink} // Use NavLink from react-router-dom
                                        to="/doc"
                                        command="/doc"
                                        onClick={() => {
                                            setCurrentPage('/doc');
                                        }}
                                        color={currentPage === '/doc' ? selectedPageColor : ''}
                                    >
                                        Generate Documents                        
                                    </MenuItem>
                                    <MenuItem
                                        icon={<FaPlayCircle />}
                                        as={NavLink}
                                        to="/playground"
                                        command="/playground"
                                        onClick={() => {
                                            setCurrentPage('/playground');
                                        }}
                                        color={currentPage === '/playground' ? selectedPageColor : ''}
                                    >
                                        Playground
                                    </MenuItem>
                                    <MenuDivider />
                                    <MenuItem                                        
                                        icon={<IoDocument />}  
                                        as={NavLink} // Use NavLink from react-router-dom
                                        to="/rag"
                                        command="/rag"
                                        onClick={() => {
                                            setCurrentPage('/rag');
                                        }}
                                        color={currentPage === '/rag' ? selectedPageColor : ''}
                                    >
                                        RAG Management  
                                    </MenuItem>
                                    <MenuDivider />                               
                                    <MenuItem 
                                        icon={<FaSignOutAlt />} 
                                        onClick={handleSignOut}                                        
                                    >
                                        Sign out
                                    </MenuItem>
                                </>
                            )}
                        </MenuList>
                    </Menu>
                </Box>
            </HStack>
            {isControlCenterOpen && (
                <Box
                    position="fixed"
                    top="16"
                    right="4"
                    width="300px"
                    padding="4"
                    backgroundColor={controlCenterBg}
                    color={controlCenterColor}
                    borderRadius="13px"
                    boxShadow="lg"
                    backdropFilter="blur(10px)"
                    zIndex="10"
                    opacity="1"
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Text fontWeight="bold">Control Center</Text>
                        <IconButton 
                            icon={<CloseIcon />} 
                            size="sm" 
                            onClick={onControlCenterClose} 
                            aria-label="Close"
                            backgroundColor="transparent"
                            _hover={{ backgroundColor: "whiteAlpha.300" }}
                        />
                    </Box>
                    <Text mt="2">
                    세션 만료시간
                    <br />
                    {tokenAndSessionInfo.sessionExpireTime? tokenAndSessionInfo.sessionExpireTime: '세션 만료시간을 확인할 수 없습니다.'}
                    </Text>
                    <Text mt="2">
                    엑세스 토큰 만료시간
                    <br />
                    {tokenAndSessionInfo.accessTokenExpireTime? tokenAndSessionInfo.accessTokenExpireTime: '엑세스 토큰 만료시간을 확인할 수 없습니다.'}
                    </Text>
                    <Text mt="2">
                    리프레시 토큰 만료시간
                    <br /> 
                    {tokenAndSessionInfo.refreshTokenExpireTime? tokenAndSessionInfo.refreshTokenExpireTime: '리프레시 토큰 만료시간을 확인할 수 없습니다.'}
                    </Text>
                </Box>
            )}
            
        </Container>
        
    );
};

export default NavigationBar;
