import { 
    Container, useDisclosure, Tabs, TabList, TabPanels, Tab, TabPanel, 
    Divider, Textarea, HStack, Flex, Spacer, IconButton, Input, Button,
    Box, Text, VStack, useToast, Switch
} from "@chakra-ui/react";
import { IoIosSend } from 'react-icons/io';
import { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { FaPaperclip } from "react-icons/fa6";
import { FaMicrophone } from "react-icons/fa";
import { DeleteIcon, ArrowForwardIcon, ArrowBackIcon} from '@chakra-ui/icons';

import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import Markdown from "react-markdown";
import newTheme from '../components/mdstyle';

import LoadingOverlay from "../components/LoadingOverlayComponent";
import { postMultitask, postMultitaskChat } from "../Api";
import { usePageColor } from "../contexts/ColorContext";
import { scrollToBottom } from "../components/animation";

import HCXVoiceComponent from '../components/HCXVoiceComponent';

const PlaygroundPage = () => {
    
    const colors = usePageColor('summary');

    const [currentNodeIndex, setCurrentNodeIndex] = useState(0);

    // Tab-specific state variables
    const [classificationInput, setClassificationInput] = useState('');
    const [classificationFile, setClassificationFile] = useState([{fileName: '', file: null, id: Date.now()}]);
    const [classificationResponse, setClassificationResponse] = useState('');

    const [summaryInput, setSummaryInput] = useState('');
    const [summaryFile, setSummaryFile] = useState([{fileName: '', file: null, id: `${Date.now()}-0`}]);
    const [summaryResponse, setSummaryResponse] = useState('');

    const [documentSummaryInput, setDocumentSummaryInput] = useState('');
    const [documentSummaryFile, setDocumentSummaryFile] = useState([{fileName: '', file: null, id: Date.now()}]);
    const [documentSummaryResponse, setDocumentSummaryResponse] = useState('');

    const [chatMessages, setChatMessages] = useState([]);
    const [chatInput, setChatInput] = useState('');

    const [thisFileDeleteIcons, setThisFileDeleteIcons] = useState([]);
    const [deletingFileIndices, setDeletingFileIndices] = useState([]);

    const [isFileInputDeleting, setIsFileInputDeleting] = useState(false);
    const [isFileInputAppearing, setIsFileInputAppearing] = useState(false);
    const [isDocumentSummaryTitleFixed, setIsDocumentSummaryTitleFixed] = useState(false);

    const [isQueryLoading, setIsQueryLoading] = useState(false);
    const [isButtonsDisappeared, setIsButtonsDisappeared] = useState(false);

    const [isClassificationLabelFormAppearing, setIsClassificationLabelFormAppearing] = useState(false);

    const {isOpen: isVoiceModalOpen, onOpen: onVoiceModalOpen, onClose: onVoiceModalClose} = useDisclosure();

    const messagesEndRef = useRef(null);
    
    const classificationInputFileRef = useRef(null);
    const summaryInputFileRef = useRef(null);
    const documentSummaryInputFileRef = useRef(null);
    const chatFileInputRef = useRef(null);

    const fileInputRefs = useMemo(() => [
        classificationInputFileRef, // 0
        summaryInputFileRef, // 1
        documentSummaryInputFileRef, // 2
        chatFileInputRef // 3
    ], []);

    const allowMultipleFileInputNode = (e, files, currentNodeIndex) => {
        
        if (files.length > 1) {
            if (currentNodeIndex !== 2) {
                toast({
                    title: 'Failed',
                    description: '파일은 1개만 선택해주세요.',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
                e.target.value = '';
                return;
            } else {
                if (files.length > 5) {
                    toast({
                        title: 'Failed',
                        description: '파일은 1개만 선택해주세요.',
                        status: 'error',
                        isClosable: true,
                        position: 'top'
                    });
                    e.target.value = '';
                    return;
                }
            }
        }
    }

    const toast = useToast();

    const setInputValue = (value) => {
        switch(currentNodeIndex) {
            case 1: setSummaryInput(value); break;
            case 2: setDocumentSummaryInput(value); break;
            case 3: setChatInput(value); break;
            default: setClassificationInput(value); break; // Case 0 and default
        }
    };
    
    const getInputFileValue = () => {
        switch(currentNodeIndex) {
          case 1: return summaryFile;
          case 2: return documentSummaryFile;
          case 3: return [{fileName: '', file: null, id: Date.now()}];
          default: return classificationFile;
        }
      };
    
    const modalFileInputButtonClick = () => {
        const currentRef = fileInputRefs[currentNodeIndex]?.current;
        if (currentRef) {
            currentRef.click();
        } else {
            console.error(`No ref found for index ${currentNodeIndex}`);
        }
    };
    
    const handleFileChange = useCallback((e) => {
        const files = Array.from(e.target.files);

        allowMultipleFileInputNode(e, files, currentNodeIndex)

        let fileData = [];

        // 파일을 하나만 넣어야 하는 경우면
        if (currentNodeIndex !== 2) {
            const file = e.target.files[0];
            if (file) {
                fileData = [{
                    fileName: file.name,
                    file: file,
                    id: Date.now()
                }];
            }
        } else {
            files.map((file, index) => {
                let fileOneData = {
                    fileName: file.name,
                    file: file,
                    id: `${Date.now()}-${index}`,
                }

                fileData.push(fileOneData);
            })
        }
                    
        switch(currentNodeIndex) {
            case 1: setSummaryFile(fileData); break;
            case 2: setDocumentSummaryFile(fileData); break;
            default: setClassificationFile(fileData); break; // Case 0 and default
        }

        setIsFileInputAppearing(true);
    }, [currentNodeIndex]);

    useEffect(() => {
        if (isFileInputAppearing) {
            const timer = setTimeout(() => {
                setIsFileInputAppearing(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [isFileInputAppearing]);

    const handleDeleteButtonClick = useCallback((index) => {
        setDeletingFileIndices(prev => [...prev, index]);
        // setIsFileInputDeleting(true);
        setTimeout(() => {
            if (currentNodeIndex === 2) {
                setDocumentSummaryFile(prev => prev.filter((_, i) => i !== index));
            } else {
                switch(currentNodeIndex) {
                    case 1: setSummaryFile([{fileName: '', file: null, id: Date.now()}]); break;                    
                    default: setClassificationFile([{fileName: '', file: null, id: Date.now()}]); break;
                }
            }
            setDeletingFileIndices(prev => prev.filter(i => i !== index));
        }, 500);
    }, [currentNodeIndex, fileInputRefs]);

    const handleChatSend = useCallback(async() => {
        if (chatInput.trim()) {
            setChatMessages(prev => [...prev, { type: 'user', content: chatInput }]);
            setChatInput('');
        }
        
        const stream = postMultitaskChat(chatInput);
        let assistantResponse  = '';

        const updateChatMessages = (newContent) => {
            setChatMessages(prev => {
                const newMessages = [...prev];
                if (newMessages.length > 0 && newMessages[newMessages.length - 1].type === 'assistant') {
                    newMessages[newMessages.length - 1].content = newContent;
                } else {
                    newMessages.push({
                        type: 'assistant',
                        content: newContent
                    });
                }
                return newMessages;
            });
        };

        for await (let token of stream) {
            if (token.query_response) {
                assistantResponse += token.query_response;
                updateChatMessages(assistantResponse);
            }
        }

    }, [chatInput]);

    useEffect(()=>{
        scrollToBottom(messagesEndRef);
    }, [chatMessages])

    const handleSendButtonClick = useCallback(async() => {
        let userPrompt = '';
        let userFile = null;
    
        switch(currentNodeIndex) {
            case 1:
                userPrompt = summaryInput;
                userFile = summaryFile[0];
                break;
            case 2:
                userPrompt = isDocumentSummaryTitleFixed? '' : documentSummaryInput;
                userFile = documentSummaryFile;
                break;
            case 3:
                await handleChatSend();
                return;
            default:
                userPrompt = classificationInput;
                userFile = classificationFile[0];
                break;
        }
    
        if (userPrompt === '' && currentNodeIndex === 0) {
            toast({
                title: 'Failed',
                description: '프롬프트는 필수입니다. 프롬프트를 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }
    
        if (userFile.file === null && currentNodeIndex !== 3) {
            toast({
                title: 'Failed',
                description: '파일은 필수입니다. 파일을 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }
    
        try {
            setIsQueryLoading(true);
            setIsButtonsDisappeared(true);
    
            const stream = postMultitask(userPrompt, userFile, currentNodeIndex, isDocumentSummaryTitleFixed);
    
            for await (let token of stream) {
                if (token.query_response) {
                    switch(currentNodeIndex) {
                        case 1:
                            setSummaryResponse(prev => prev + token.query_response);
                            break;
                        case 2:
                            setDocumentSummaryResponse(prev => prev + token.query_response);
                            break;
                        default: // Case 0 and any unexpected values
                            setClassificationResponse(prev => prev + token.query_response);
                            break;
                    }
                    console.log(token.query_response);
                    if (token.last_answer === "Y") {
                        break;
                    }
                }
            }
        } catch (error) {
            toast({
                title: 'Failed',
                description: error.message ? error.message : '오류가 일어났습니다. 시스템 관리자에게 문의하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        } finally {
            setIsQueryLoading(false);
            setIsButtonsDisappeared(false);
            if (currentNodeIndex !== 3) {
                handleDeleteButtonClick();
            }
        }   
    }, [currentNodeIndex, classificationInput, classificationFile, summaryInput, summaryFile, documentSummaryInput, documentSummaryFile, toast, handleChatSend, handleDeleteButtonClick]);
    
    const handleBackToMainForm = useCallback(() => {
        setIsClassificationLabelFormAppearing(false);
    }, []);

    const allowNodeIndexTwoThisFileInputLeftIcon = (index) => {
        setThisFileDeleteIcons(prev => prev.map((icon, i) => i === index ? true : icon));
    };
    
    const resetFileDeleteIcons = (index) => {
        setThisFileDeleteIcons(prev => prev.map((icon, i) => i === index ? false : icon));
    };

    useEffect(() => {
        const fileValues = currentNodeIndex === 2 ? documentSummaryFile : 
                        currentNodeIndex === 1 ? summaryFile :
                        classificationFile;
    
    setThisFileDeleteIcons(new Array(fileValues.length).fill(false));
    }, [currentNodeIndex, documentSummaryFile, summaryFile, classificationFile]);

    const renderPlacholderForDocumentSummary = () =>{

        if (isDocumentSummaryTitleFixed) {
            return "자동 제목 생성이 될 예정입니다."   
        } else {
            return "제목을 입력해주세요."
        }
    }

    return (
        <Container
            maxW="100%" 
            minH="100vh"
            bg={colors.ContainerMainBg}
            py='4'
        >
            <HCXVoiceComponent 
                isVoiceModalOpen={isVoiceModalOpen} 
                onVoiceModalClose={onVoiceModalClose} 
            />
            <Container
                maxW="container.sm"
                borderRadius="16px"
                p='4'
                minH='90vh'
                bg={colors.ContainerCenterBg}
            >
                <LoadingOverlay isLoading={isQueryLoading} />
                {!isClassificationLabelFormAppearing && (
                    <Flex direction='column' minH='85vh'>                                   
                    <Tabs variant="enclosed" position='relative' index={currentNodeIndex} onChange={setCurrentNodeIndex}>                     
                        {!isButtonsDisappeared && (
                        <TabList 
                            borderWidth='0'
                            style={{
                                animation: isQueryLoading ? 'fadeOutUp 1s ease' : 
                                        !isQueryLoading && !isButtonsDisappeared ? 'fadeInDown 1s ease' : 'none'
                            }}
                        >
                            <Tab 
                                borderRadius='20px'
                                _selected={{ bg: "#c84557", color: 'white', borderColor: '#c84557' }}
                                transition='all 0.3s ease'
                            >
                                분류
                            </Tab>
                            <Tab 
                                borderRadius='20px'
                                border='2px solid transparent'
                                _selected={{ bg: '#c84557', borderColor: '#c84557', color: 'white'}}
                                transition='all 0.3s ease'
                                mr='3'
                            >
                                요약
                            </Tab>
                            <Tab 
                                borderRadius='20px'
                                border='2px solid transparent'
                                _selected={{ bg: '#c84557', borderColor: '#c84557', color: 'white'}}
                                transition='all 0.3s ease'
                                mr='3'
                            >
                                요약 문서
                            </Tab>
                            <Tab 
                                borderRadius='20px'
                                border='2px solid transparent'
                                _selected={{ bg: '#c84557', borderColor: '#c84557', color: 'white'}}
                                transition='all 0.3s ease'
                                mr='3'
                            >
                                채팅
                            </Tab>
                            <Spacer />
                            {currentNodeIndex === 0 && (
                                <IconButton 
                                    borderRadius='13px'                                    
                                    icon={<ArrowForwardIcon />}
                                    borderColor="#c84557"
                                    borderWidth='2px'
                                    color="#c84557"
                                    height="40px"
                                    bg='transparent'
                                    _hover={{ bg: '#c84557', color: 'white' }}
                                    transition='all 0.5s ease'
                                    onClick={()=>{
                                        setIsClassificationLabelFormAppearing(true);
                                    }}
                                />
                            )}                            
                        </TabList>
                        )}
                        <Divider 
                        mt='4' 
                        borderWidth='1px'
                        opacity={isQueryLoading ? 0 : 1}
                        transition='all 1s ease'
                        />
                        <TabPanels>                        
                            <TabPanel>
                                <Markdown 
                                    components={ChakraUIRenderer(newTheme)} 
                                    skipHtml
                                    >       
                                        {classificationResponse}                                   
                                </Markdown>
                            </TabPanel>
                            <TabPanel>
                                <Markdown 
                                    components={ChakraUIRenderer(newTheme)} 
                                    skipHtml
                                    >       
                                        {summaryResponse}                                   
                                </Markdown> 
                            </TabPanel>
                            <TabPanel ref={messagesEndRef}>                                
                                <Markdown 
                                    components={ChakraUIRenderer(newTheme)} 
                                    skipHtml
                                    >       
                                        {documentSummaryResponse}                                   
                                </Markdown>
                                <HStack>
                                    <Switch 
                                        id='documentSummarySwitch'
                                        onChange={()=>{
                                            setIsDocumentSummaryTitleFixed(prev=>!prev)
                                        }}
                                    />
                                    <Text>
                                        {isDocumentSummaryTitleFixed? "자동 제목 활성화!":"활성화되면 HCX가 자동으로 제목을 생성해줍니다."}
                                    </Text>
                                </HStack> 
                            </TabPanel>
                            <TabPanel>
                                <VStack align="stretch" spacing={4} mb={4}>
                                    {chatMessages.map((message, index) => (
                                        <Box 
                                            key={index} 
                                            bg={message.type === 'user' ? 'blue.100' : 'green.100'} 
                                            p={2} 
                                            borderRadius="md"
                                        >
                                            <Text fontWeight="bold">{message.type === 'user' ? 'You:' : 'AI:'}</Text>
                                            <Text>{message.content}</Text>
                                        </Box>
                                    ))}
                                </VStack>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>                
                    <Spacer />                    
                    {!isButtonsDisappeared && (
                        <>
                        <HStack
                        style={{
                            animation: isQueryLoading ? 'fadeOutDown 1s ease' : 
                                    !isQueryLoading && !isButtonsDisappeared ? 'fadeInUp 1s ease' : 'none'
                        }}
                        >
                        {getInputFileValue().length > 0 && getInputFileValue()[0].file && currentNodeIndex !== 3 && (
                            currentNodeIndex === 2 ?
                            <>
                                {getInputFileValue().map((fileValue, index) => (
                                    <Button
                                        key={fileValue.id}
                                        borderRadius='13px'
                                        p='2'
                                        minW='100px'
                                        leftIcon={thisFileDeleteIcons[index] ? <DeleteIcon /> : <FaPaperclip />}
                                        _hover={{
                                        bg: 'red.500',
                                        color: 'white',
                                        transform: 'translateX(-10px)'
                                        }}
                                        onMouseEnter={() => allowNodeIndexTwoThisFileInputLeftIcon(index)}
                                        onMouseLeave={() => resetFileDeleteIcons(index)}
                                        onClick={() => handleDeleteButtonClick(index)}
                                        transition="all 0.5s ease"                            
                                        opacity={deletingFileIndices.includes(index) ? 0 : 1}                            
                                        style={{
                                            animation: isFileInputAppearing ? 'fadeInRight 0.5s ease' : 
                                                        deletingFileIndices.includes(index) ? 'fadeOutLeft 0.5s ease' : 'none'
                                        }}
                                        whiteSpace="nowrap"
                                        overflow="hidden"
                                        textOverflow="ellipsis"
                                        display="block"
                                        maxW='100%'
                                    >
                                        {`${fileValue.fileName}`}
                                    </Button>
                                ))}            
                            </>                            
                            :
                            <Button
                                key={getInputFileValue()[0].id}
                                borderRadius='13px'
                                p='2'
                                minW='100px'
                                leftIcon={thisFileDeleteIcons[0] ? <DeleteIcon /> : <FaPaperclip />}
                                _hover={{
                                bg: 'red.500',
                                color: 'white',
                                transform: 'translateX(-10px)'
                                }}
                                onMouseEnter={() => allowNodeIndexTwoThisFileInputLeftIcon(0)}
                                onMouseLeave={() => resetFileDeleteIcons(0)}
                                onClick={() => handleDeleteButtonClick(0)}
                                transition="all 0.5s ease"                            
                                opacity={isFileInputDeleting ? 0 : 1}                            
                                style={{
                                animation: isFileInputAppearing ? 'fadeInRight 0.5s ease' : 
                                            isFileInputDeleting ? 'fadeOutLeft 0.5s ease' : 'none'
                                }}
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                display="block"
                                maxW='100%'
                            >
                                {getInputFileValue()[0].fileName}
                            </Button>
                        )}
                        </HStack>
                        <HStack 
                            alignItems="flex-end" 
                            mt='5'
                            style={{
                                animation: isQueryLoading ? 'fadeOutDown 1s ease' : 
                                        !isQueryLoading && !isButtonsDisappeared ? 'fadeInUp 1s ease' : 'none'
                            }}
                        >
                            <Textarea 
                                placeholder={currentNodeIndex === 0 
                                    // 분류
                                    ? "콤마로 구분하여 넣어주세요 ex) 보고서, 신청서, 회의록"
                                    : currentNodeIndex === 3
                                    // 채팅
                                    ? "채팅 메시지를 입력하세요"
                                    : currentNodeIndex === 2
                                    // 요약 문서
                                    ? renderPlacholderForDocumentSummary()
                                    // 요약
                                    : "요약보고서"
                                }
                                borderRadius="13px"
                                borderWidth="2px"
                                mr={2}
                                flex={1}
                                minH="40px"
                                maxH="200px"
                                overflow="hidden"
                                readOnly={isDocumentSummaryTitleFixed? true: false}
                                bg={isDocumentSummaryTitleFixed? colors.InputBoxBg:colors.ContainerCenterBg}
                                resize="none"
                                value={currentNodeIndex === 0 ? classificationInput :
                                       currentNodeIndex === 1 ? summaryInput :
                                       currentNodeIndex === 2 ? documentSummaryInput :
                                       chatInput}
                                onChange={(e) => setInputValue(e.target.value)}
                                rows={1}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) {
                                        e.preventDefault();
                                        handleSendButtonClick();
                                    }                                
                                }}
                            />

                            <IconButton
                                borderRadius="13px"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSendButtonClick()}}
                                icon={<IoIosSend />}
                                borderColor="#c84557"
                                borderWidth='2px'
                                color="#c84557"
                                height="40px"
                                bg='transparent'
                                _hover={{ bg: '#c84557', color: 'white' }}
                            />
                            {currentNodeIndex === 3 && (
                                <IconButton
                                    icon={< FaMicrophone/>}
                                    borderRadius='13px'
                                    colorScheme='green'
                                    onClick={onVoiceModalOpen}
                                /> 
                            )}                                
                            {currentNodeIndex !== 3 && (
                                <IconButton 
                                    borderRadius='13px'
                                    onClick={modalFileInputButtonClick}
                                    icon={<FaPaperclip  />}
                                    borderColor="#c84557"
                                    borderWidth='2px'
                                    color="#c84557"
                                    height="40px"
                                    bg='transparent'
                                    _hover={{ bg: '#c84557', color: 'white' }}
                                    transition='all 0.5s ease'
                                />
                            )}                           
                        </HStack> 
                        </>                         
                    )}                            
                </Flex>     
                )}
                {isClassificationLabelFormAppearing && (
                    <Flex 
                        direction='column' 
                        minH='85vh'
                        style={{
                            animation: isQueryLoading ? 'fadeOutUp 1s ease' : 
                                    !isQueryLoading && !isButtonsDisappeared ? 'fadeInDown 1s ease' : 'none'
                        }}
                    >                    
                        <Flex>
                            <Text>분류 라벨을 입력해주세요.</Text>
                            <Spacer />
                            <IconButton 
                                borderRadius='13px'
                                icon={<ArrowBackIcon />}
                                borderColor="#c84557"
                                borderWidth='2px'
                                color="#c84557"
                                height="40px"
                                bg='transparent'
                                _hover={{ bg: '#c84557', color: 'white' }}
                                transition='all 0.5s ease'
                                onClick={handleBackToMainForm}
                            />
                        </Flex>
                    </Flex>
                )}
                
                                       
            </Container>
            
            {fileInputRefs.map((ref, index) => (
                <Input
                    key={index}
                    type="file"
                    ref={ref}
                    hidden
                    accept=".pdf, .hwp, .txt, .csv"
                    onChange={handleFileChange}
                    multiple
                />
            ))}
        </Container>
    )
}

export default PlaygroundPage;