import { useCallback, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ChakraProvider, useToast } from '@chakra-ui/react';

import DocPage from './pages/DocPage';
import SignInPage from './pages/SignInPage';
import RagPage from './pages/RagPage';
import ChatPage from './pages/ChatPage';
import PlaygroundPage from './pages/PlaygroundPage';
import { useGlobal } from './contexts/GlobalContext';

import NavigationBar from './components/NavigationBarComponent';
import { 
    calculateSessionExpireTime, calculateAccessTokenExpireTime, 
    calculateRefreshTokenExpireTime, unixToReadableTime 
} from './components/TimeCalculatingComponent';
import { getRefresh } from './Api';
import { v4 as uuidv4 } from 'uuid'; 
import Cookies from 'js-cookie';

const App = () => {
    const toast = useToast();
    const { setIsSignedIn } = useGlobal();  // Context에서 상태를 가져옴

    const handleGetRefresh = useCallback(async () => {
        try {
            const data = await getRefresh();

            if (data.result === 1) {
                toast({
                    title: '토큰이 자동 재발급 되었습니다.',
                    description: '토큰이 자동 재발급 되었습니다.',
                    status: 'info',
                    isClosable: true,
                    position: 'top'
                });

                setIsSignedIn(true);

                localStorage.setItem('session-exp', calculateSessionExpireTime());
                localStorage.setItem('access-token-exp', calculateAccessTokenExpireTime());
                localStorage.setItem('refresh-token-exp', calculateRefreshTokenExpireTime());
                return;
            } else {
                toast({
                    title: 'Fetch Failed',
                    description: '토큰 자동 갱신 실패. 재로그인 하세요.',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
                setIsSignedIn(false);
                return;
            }
        } catch (error) {
            toast({
                title: 'Fetch Failed',
                description: '토큰 자동 갱신 실패. 재로그인 하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            setIsSignedIn(false);
            return;
        }
    }, [toast, setIsSignedIn]);

    const checkExpireTime = useCallback(async () => {
        const sessionExpireTime = parseInt(localStorage.getItem('session-exp'));
        const accessTokenExpireTime = parseInt(localStorage.getItem('access-token-exp'));
        const refreshTokenExpireTime = parseInt(localStorage.getItem('refresh-token-exp'));

        // console.log("----------------클라이언트 이용 기록 시작----------------");
        // console.log("현재 시간 : ", unixToReadableTime(Date.now()));
        // console.log("세션 만료 시간: ", unixToReadableTime(sessionExpireTime));
        // console.log("엑세스 토큰 만료 시간: ", unixToReadableTime(accessTokenExpireTime));
        // console.log("갱신 토큰 만료 시간: ", unixToReadableTime(refreshTokenExpireTime));
        // console.log("엑세스 토큰: ", localStorage.getItem('access-token'));
        // console.log("리프레시 토큰: ", localStorage.getItem('refresh-token'));
        // console.log("현재 로그인 상태: ", isSignedIn);

        if (sessionExpireTime <= Date.now() && sessionExpireTime !== null) {
            console.log("1. 세션 만료됨");
            // localStorage.removeItem('access-token');
            // localStorage.removeItem('refresh-token');
            // localStorage.removeItem('session-exp');
            // localStorage.removeItem('access-token-exp');
            // localStorage.removeItem('refresh-token-exp');    
            setIsSignedIn(false);
            return false;
        } else if (sessionExpireTime > Date.now() && sessionExpireTime !== null) {
            console.log("2. 세션 시간 갱신됨");
            localStorage.setItem('session-exp', calculateSessionExpireTime());
        }

        if (sessionExpireTime > Date.now() && accessTokenExpireTime <= Date.now() && refreshTokenExpireTime > Date.now()) {
            console.log("3. 엑세스 토큰 만료됨, 갱신 시도");

            await handleGetRefresh();
        }

        // console.log("----------------클라이언트 이용 기록 끝----------------");

        let returnValue = {
            nowTime: unixToReadableTime(Date.now()),
            sessionExpireTime: unixToReadableTime(sessionExpireTime),
            accessTokenExpireTime: unixToReadableTime(accessTokenExpireTime),
            refreshTokenExpireTime: unixToReadableTime(refreshTokenExpireTime)
        };
        return returnValue;
    }, [handleGetRefresh, setIsSignedIn]);

    const checkUUID = () => {
        let uuid = Cookies.get('uuid');
    
        if (!uuid) {
          uuid = uuidv4();
          Cookies.set('uuid', uuid, { expires: 365 });
        } else {
        }
    
        return uuid;
    };
    
    useEffect(() => {
        checkUUID();
    }, []);
    
    return (
        <ChakraProvider>
            <BrowserRouter>                
            <NavigationBar 
                checkExpireTime={checkExpireTime} 
            />
                <Routes>                
                    <Route 
                        path='/' 
                        element={
                            <ChatPage />} 
                    />
                    <Route
                        path='/signin'
                        element={
                            <SignInPage/>}
                    />
                    <Route 
                        path='/rag' 
                        element={
                            <RagPage 
                                checkExpireTime={checkExpireTime}
                            />}/>
                    <Route
                        path='/doc'
                        element={
                            <DocPage/>} 
                    />
                    <Route
                        path='/playground'
                        element={
                            <PlaygroundPage/>}
                    />
                </Routes>
            </BrowserRouter>           
        </ChakraProvider>
    );
}

export default App;
